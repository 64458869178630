













































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getComponent, jsonParse } from "@/utils/helpers";
import accountModule from "@/store/modules/accountModule";
import assetsModule from "@/store/modules/assetsModule";
import scheduleModule from "@/store/modules/scheduleModule";
import { mixins } from "vue-class-component";
import Multiselect from "vue-multiselect";
import workspaceModule from "@/store/modules/workspaceModule";

@Component({
  components: {
	AddShift: () => getComponent('schedule/AddShift'),
	AddShiftTemplate: () => getComponent('schedule/AddShiftTemplate'),
    Multiselect,
  },
})
export default class NewShiftWrapper extends mixins() {
 
  popupTypeSelected = "NewShift"

  created() {

  }

  shiftTabSelected(tab) {
	  if (tab == "NewShift") {
		  this.popupTypeSelected = "NewShift"
	  } else {
		  this.popupTypeSelected = "NewShiftTemplate"
	  }
  }

  closeAddShiftPopup() {
    this.$emit("close-add-shift-popup");
  }
	closeAddShiftTemplatePopup() {
		// this.popupBackground = false
		// this.addShiftTemplatePopupShowing = false
		// this.$emit("hide-add-new-job-clicked")
		this.$emit("close-add-shift-template-popup");
	}

}
